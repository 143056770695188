import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Invoice, Order, S3ItemReference, ServiceLocationTransport, UniversalService } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { blobToBase64, formatEmailDestination, getInvoiceTerm, getPrimaryContact, recalculateInvoiceTotal } from '@wayste/utils';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { getCustomerToAndCCEmails } from '../../utils';
import { getOrderBillStatusFromPayables, getStatusFromReceivables } from '../../utils/invoice-utils';

export type ServiceGroupingExtendedServiceOrders = Omit<UniversalService.ServiceGrouping, 'serviceOrders'> & {
    serviceOrders: (UniversalService.ServiceOrder & {
        receivables: Invoice.ReceivableTransport[];
        payables: Invoice.PayableTransport[];
        receivablesStatus: Invoice.InvoiceStatus | 'NONE';
        payablesStatus: Invoice.InvoiceStatus | 'NONE';
    })[];

    receivables: Invoice.ReceivableTransport[];
};

/**
 * This type is defined in the billing page context only. It merges AllianceOrderTransport and UniversalService.ServiceOrder so that we only need one billing page to handle both.
 * If you need additional fields from either add them and map them in here.
 */
export type BillingPageOnlyOrderType = {
    paymentMethod?: string;
    paymentTerm?: string;
    images: S3ItemReference[];
    serviceLocation: ServiceLocationTransport;
    poNumber?: string;
    rentExtensionFee: number;
    vendorName?: string;
    /** roll-off id and US serviceOrder id map to id */
    id: string;
    /** roll-off haulerID and US vendorID map to vendorID */
    vendorID?: string;
    /** roll-off taxRate is mapped. US doesn't have it and is hardcoded */
    taxRate: number;
    /** roll-off orderNumber and US fullOrderNumber map to orderNumber */
    orderNumber: string;
    order?: Order.AllianceOrderTransport;
    serviceOrder?: UniversalService.ServiceOrder;
};

type BillingContextType = {
    setListView: (listView: 'roll-off' | 'universal-service' | 'receivables' | 'payables') => void;
    /**
     * The list view changes which list is visible to the user on the main billing page.
     */
    listView: 'roll-off' | 'universal-service' | 'receivables' | 'payables';
    /**
     * selectedOrderType is the type of order that is being viewed. It is used to determine which API to call and how to handle the data.
     */
    selectedOrderType?: 'roll-off' | 'universal-service';
    refetchOrder: () => void;
    handleReceivableSave: () => void;
    handlePayableSave: () => void;
    handleReceivableChanges: (
        receivable: Invoice.ReceivableTransport,
        index?: number,
        options?: {
            isAPIResponse?: boolean;
        },
    ) => void;
    handlePayableChanges: (
        payable: Invoice.PayableTransport,
        index?: number,
        options?: {
            isAPIResponse?: boolean;
        },
    ) => void;
    handleCreateNewReceivable: () => void;
    handleCreateNewPayable: (data: {
        haulerID: string;
        invoiceNumber: string;
        haulerName: string;
        lineItems?: Invoice.LineItemInputTransport[];
    }) => void;
    setView: (view: 'invoice' | 'bill') => void;
    handleVoidIssuedInvoice: (receivableId: string) => void;
    showInvoiceDrawer: boolean;
    /**
     * When a subscription grouping id is clicked with no service order id, this drawer will open to show the invoices for that subscription.
     */
    showSubscriptionInvoicesDrawer: boolean;
    /**
     * This type is defined in the billing page context only. It merges AllianceOrderTransport and UniversalService.ServiceOrder so that we only need one billing page to handle both.
     */
    selectedOrder: BillingPageOnlyOrderType | null;
    selectedServiceGrouping: ServiceGroupingExtendedServiceOrders | null;
    customer: Customer.AllianceCustomerTransport | null;
    receivables: Invoice.ReceivableTransport[];
    payables: Invoice.PayableTransport[];
    /** True if user edits a receive or payable */
    hasChanges: boolean;
    isLoadingOrder: boolean;
    /**
     * In InvoiceDetails controls switching between Receivables and Payables
     */
    view: 'invoice' | 'bill';
};

const initialValue: BillingContextType = {
    setListView: () => null,
    selectedOrderType: 'roll-off',
    listView: 'roll-off',
    refetchOrder: () => null,
    handleReceivableChanges: () => null,
    handlePayableChanges: () => null,
    handleReceivableSave: () => null,
    handlePayableSave: () => null,
    handleCreateNewReceivable: () => null,
    handleCreateNewPayable: () => null,
    setView: () => null,
    handleVoidIssuedInvoice: () => null,
    showInvoiceDrawer: false,
    showSubscriptionInvoicesDrawer: false,
    selectedOrder: null,
    selectedServiceGrouping: null,
    customer: null,
    receivables: [],
    payables: [],
    hasChanges: false,
    isLoadingOrder: false,
    view: 'invoice',
};

export const BillingContext = createContext(initialValue);

export const BillingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const client = useWaysteClient();
    const { useConfirmationDialog, setShowToast } = useSourContext();
    const location = useLocation();
    const { getConfirmation } = useConfirmationDialog();
    const [selectedOrderID, setSelectedOrderID] = useState<
        { orderID?: string; serviceGroupingID?: string; serviceOrderID?: string } | undefined
    >(undefined);
    const [showInvoiceDrawer, setShowInvoiceDrawer] = useState(initialValue.showInvoiceDrawer);
    const [showSubscriptionInvoicesDrawer, setShowSubscriptionInvoicesDrawer] = useState(initialValue.showSubscriptionInvoicesDrawer);
    const [isLoadingOrder, setIsLoadingOrder] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(initialValue.selectedOrder);
    const [selectedServiceGrouping, setSelectedServiceGrouping] = useState(initialValue.selectedServiceGrouping);
    const [customer, setCustomer] = useState(initialValue.customer);
    const [receivables, setReceivables] = useState(initialValue.receivables);
    const [payables, setPayables] = useState(initialValue.payables);
    /** List of receivable indexes that have, passed to the save handler */
    const [receivablesChanges, setReceivablesChanges] = useState<number[]>([]);
    /** List of payable indexes that have changed, passed to the save handler */
    const [payablesChanges, setPayablesChanges] = useState<number[]>([]);
    const [hasChanges, setHasChanges] = useState(initialValue.hasChanges);
    const [view, setView] = useState(initialValue.view);
    const [listView, setListView] = useState(initialValue.listView);
    const [selectedOrderType, setSelectedOrderType] = useState(initialValue.selectedOrderType);

    // Fetch the grouping and receivables and payables to make a list of service orders for the user to select
    const handleFetchServiceGrouping = async (serviceGroupingID: string) => {
        if (selectedServiceGrouping?.id === serviceGroupingID) return;
        setIsLoadingOrder(true);
        try {
            const response = await client.universalService().serviceGrouping.fetch(serviceGroupingID);
            const receivableResponse = await client.invoice().adminPortal.receivable.query({ serviceGroupingID: response.id });
            const payableResponse = await client.invoice().adminPortal.payable.query({ serviceGroupingID: response.id });
            const processedGrouping = { ...response };
            processedGrouping.serviceOrders = response.serviceOrders.map((serviceOrder) => {
                const serviceOrderReceivables: Invoice.ReceivableTransport[] = [];

                receivableResponse.results.forEach((receivable) => {
                    if (receivable.invoiceDetails.lineItems.some((li) => li.serviceOrderID === serviceOrder.id)) {
                        serviceOrderReceivables.push(receivable);
                    }
                });

                const serviceOrderPayables = payableResponse.results.filter(
                    (payable) => payable.invoiceDetails.serviceOrderID === serviceOrder.id,
                );
                return {
                    ...serviceOrder,
                    receivables: serviceOrderReceivables || [],
                    payables: serviceOrderPayables || [],
                    receivablesStatus: getStatusFromReceivables(serviceOrderReceivables),
                    payablesStatus: getOrderBillStatusFromPayables(serviceOrderPayables),
                };
            });
            processedGrouping.serviceOrders.sort((a, b) => {
                if (!a.startDate || !b.startDate) return 0;
                if (a.startDate !== b.startDate) {
                    return a.startDate > b.startDate ? -1 : 1;
                }
                if (a.vendorName !== b.vendorName) {
                    return (a.vendorName || '') > (b.vendorName || '') ? 1 : -1;
                }
                return a.metadata.createdAt > b.metadata.createdAt ? 1 : -1;
            });
            setSelectedServiceGrouping({
                ...processedGrouping,
                receivables: receivableResponse.results,
            } as ServiceGroupingExtendedServiceOrders);
        } catch (error) {
            console.warn('handleFetchServiceGrouping error: ', error);
            setShowToast({
                severity: 'error',
                message: 'Something went wrong loading the service grouping. Please refresh the page and try again.',
            });
            setSelectedServiceGrouping(null);
        } finally {
            setIsLoadingOrder(false);
        }
    };

    const checkQueryParams = async () => {
        const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
        // If check for setting up and removing the Invoice Drawer
        if (parsed.id) {
            if (listView === 'universal-service') setListView('roll-off');
            setSelectedOrderType('roll-off');
            setSelectedOrderID({ orderID: parsed.id.toString() });
        } else if (parsed.serviceGroupingID && parsed.serviceOrderID) {
            if (listView === 'roll-off') setListView('universal-service');
            setSelectedOrderType('universal-service');
            setSelectedOrderID({
                serviceGroupingID: parsed.serviceGroupingID.toString(),
                serviceOrderID: parsed.serviceOrderID.toString(),
            });
        } else {
            if (selectedOrder?.order?.status === 'COMPLETED' && receivables.some((rec) => rec.invoiceDetails.status === 'DRAFT')) {
                const confirmed = await getConfirmation({
                    title: 'Receivables Still in Draft',
                    message:
                        'Are you sure that you want to leave this page? You have receivables that are still in draft and have not been issued to the customer.',
                    confirmText: 'Leave in Draft',
                    cancelText: 'Stay on Page',
                });
                if (!confirmed) return;
            }
            setSelectedOrderType(undefined);
            setSelectedOrderID(undefined);
        }
        // If check for setting up and removing the Subscription Invoice Drawer
        if (parsed.serviceGroupingID && !parsed.serviceOrderID) {
            setShowSubscriptionInvoicesDrawer(true);
        } else if (!parsed.serviceGroupingID && !parsed.serviceOrderID) {
            setShowSubscriptionInvoicesDrawer(false);
            setSelectedServiceGrouping(null);
        }
        if (parsed.serviceGroupingID) {
            handleFetchServiceGrouping(parsed.serviceGroupingID.toString());
        }
        if (parsed.view) {
            setView(parsed.view as 'invoice' | 'bill');
        }
    };

    useEffect(() => {
        checkQueryParams();
    }, [location.search]);

    useEffect(() => {
        setHasChanges(payablesChanges.length + receivablesChanges.length > 0);
    }, [receivablesChanges.length, payablesChanges.length]);

    const fetchOrderWithInvoices = async (orderID: string) => {
        setIsLoadingOrder(true);
        setSelectedOrder(null);
        setCustomer(null);
        setReceivables([]);
        setPayables([]);
        setReceivablesChanges([]);
        setPayablesChanges([]);

        const order = await client.order().adminPortal.fetch(orderID);

        if (!order) {
            throw new Error('Service Order not found');
        }

        // Get receivables, payables, and customer
        try {
            const [receivablesResponse, payablesResponse, customerResponse] = await Promise.all([
                client.invoice().adminPortal.receivable.query({
                    orderID,
                }),
                client.invoice().adminPortal.payable.query({
                    orderID,
                }),
                client.customer().adminPortal.fetch(order.allianceCustomerID),
            ]);

            setReceivables(receivablesResponse.results);
            setPayables(payablesResponse.results);
            setCustomer(customerResponse);
            // SORT THEM  BY INVOICE NUMBER
            // order.invoices = order.invoices.sort((a, b) => (+a.invoiceNumber > +b.invoiceNumber ? 1 : -1));
            setSelectedOrder({
                id: order.id,
                paymentMethod: order.paymentMethod || undefined,
                paymentTerm: order.paymentTerm || undefined,
                images: order.images,
                serviceLocation: order.serviceLocation,
                poNumber: order.poNumber || undefined,
                rentExtensionFee: order.rentExtensionFee || 0,
                vendorName: order.vendorName,
                vendorID: order.haulerID || undefined,
                taxRate: order.taxRate || 0,
                orderNumber: order.orderNumber ? order.orderNumber.toString() : '',
                order,
            });
        } catch (error) {
            console.error(error, orderID);
            setShowToast({
                severity: 'error',
                message: 'Something went wrong loading payables and receivables. Please refresh the page and try again.',
            });
        } finally {
            setIsLoadingOrder(false);
        }
    };

    const fetchServiceOrderWithInvoices = async (serviceGroupingID: string, serviceOrderId: string) => {
        setIsLoadingOrder(true);
        setSelectedOrder(null);
        setCustomer(null);
        setReceivables([]);
        setPayables([]);
        setReceivablesChanges([]);
        setPayablesChanges([]);
        // GET THE SERVICE ORDER/BILLING TRANSPORT WHO KNOWS ONLY GOD AND @jay-depot
        const grouping = await client.universalService().serviceGrouping.fetch(serviceGroupingID);

        // order is the service order
        const serviceOrder = grouping.serviceOrders.find((order) => order.id === serviceOrderId);

        if (!serviceOrder) {
            throw new Error('Service Order not found');
        }

        // Get receivables, payables, and customer
        try {
            const [receivablesResponse, payablesResponse, customerResponse] = await Promise.all([
                grouping.invoiceGroupings.length
                    ? client.invoice().adminPortal.receivable.query({
                          productInvoiceGroupingID: grouping.invoiceGroupings.map((invoiceGrouping) => invoiceGrouping.id).join(','),
                      })
                    : { results: [] },
                grouping.serviceOrders.length
                    ? client.invoice().adminPortal.payable.query({
                          serviceOrderID: serviceOrderId,
                      })
                    : { results: [] },
                client.customer().adminPortal.fetch(grouping.customerID),
            ]);

            setReceivables(receivablesResponse.results);
            setReceivables(
                receivablesResponse.results.filter((rec) =>
                    rec.invoiceDetails.lineItems.some((lineItem) => lineItem.serviceOrderID === serviceOrderId),
                ),
            );
            setPayables(payablesResponse.results);
            setCustomer(customerResponse);

            // SORT THEM  BY INVOICE NUMBER
            // order.invoices = order.invoices.sort((a, b) => (+a.invoiceNumber > +b.invoiceNumber ? 1 : -1));
            setSelectedOrder({
                id: serviceOrder.id,
                paymentMethod: grouping.paymentMethod || undefined,
                paymentTerm: grouping.paymentTerm || undefined,
                images: serviceOrder.images || [],
                serviceLocation: serviceOrder.serviceLocation,
                poNumber: serviceOrder.poNumber || undefined,
                rentExtensionFee: 0,
                vendorName: serviceOrder.vendorName,
                vendorID: serviceOrder.vendorID || undefined,
                taxRate: 0.06625,
                orderNumber: serviceOrder.fullOrderNumber || '',
                serviceOrder: serviceOrder,
            });
        } catch (error) {
            console.error(error, serviceGroupingID, serviceOrderId);
            setShowToast({
                severity: 'error',
                message: 'Something went wrong loading payables and receivables. Please refresh the page and try again.',
            });
        } finally {
            setIsLoadingOrder(false);
        }
    };

    useEffect(() => {
        if (selectedOrderID?.orderID) {
            setShowInvoiceDrawer(true);
            fetchOrderWithInvoices(selectedOrderID.orderID);
        } else if (selectedOrderID?.serviceGroupingID && selectedOrderID?.serviceOrderID) {
            setShowInvoiceDrawer(true);
            fetchServiceOrderWithInvoices(selectedOrderID.serviceGroupingID, selectedOrderID.serviceOrderID);
        } else {
            setSelectedOrder(null);
            setReceivables([]);
            setPayables([]);
            setCustomer(null);
            setReceivablesChanges([]);
            setPayablesChanges([]);
            setShowInvoiceDrawer(false);
        }
    }, [selectedOrderID]);

    const refetchOrder = () => {
        if (selectedOrderID?.orderID) {
            fetchOrderWithInvoices(selectedOrderID.orderID);
        } else if (selectedOrderID?.serviceGroupingID && selectedOrderID?.serviceOrderID) {
            fetchServiceOrderWithInvoices(selectedOrderID.serviceGroupingID, selectedOrderID.serviceOrderID);
        }
    };

    const handleReceivableChanges = (
        receivable: Invoice.ReceivableTransport,
        index?: number,
        options?: {
            isAPIResponse?: boolean;
        },
    ) => {
        const updatedReceivables = [...receivables];
        // if it doesn't have an id it hasn't been saved yet so we can just remove it
        if (!receivable.id && typeof index === 'number' && receivable.invoiceDetails.void) {
            updatedReceivables.splice(index, 1);
            setReceivables(updatedReceivables);
            return;
        }

        if (!options?.isAPIResponse) {
            receivable.invoiceDetails = recalculateInvoiceTotal(receivable.invoiceDetails);
        }
        if (index || index === 0) {
            updatedReceivables[index] = receivable;
        } else {
            // it's new
            index = receivables.length;
            updatedReceivables.push(receivable);
        }
        // return;
        setReceivables(updatedReceivables);

        if (options?.isAPIResponse) {
            // remove the index from the changes array
            setReceivablesChanges(Array.from(new Set([...receivablesChanges].filter((i) => i !== index))));
        } else {
            setReceivablesChanges(Array.from(new Set([...receivablesChanges, index])));
        }
    };

    const handlePayableChanges = (
        payable: Invoice.PayableTransport,
        index?: number,
        options?: {
            isAPIResponse?: boolean;
        },
    ) => {
        const updatedPayables = [...payables];
        // if it doesn't have an id it hasn't been saved yet so we can just remove it
        if (!payable.id && typeof index === 'number' && payable.invoiceDetails.void) {
            updatedPayables.splice(index, 1);
            setPayables(updatedPayables);
            return;
        }

        if (!options?.isAPIResponse) {
            payable.invoiceDetails = recalculateInvoiceTotal(payable.invoiceDetails);
        }

        if (index || index === 0) {
            updatedPayables[index] = payable;
        } else {
            // it's new
            index = payables.length;
            updatedPayables.push(payable);
        }
        setPayables(updatedPayables);
        if (options?.isAPIResponse) {
            // remove the index from the changes array
            setPayablesChanges(Array.from(new Set([...payablesChanges].filter((i) => i !== index))));
        } else {
            setPayablesChanges(Array.from(new Set([...payablesChanges, index])));
        }
    };

    const handleReceivableSaveRollOff = async () => {
        if (!selectedOrder) throw new Error('Order is not loaded');
        setIsLoadingOrder(true);
        // save inv
        try {
            await Promise.all(
                receivablesChanges.map(async (receivableIndex) => {
                    const receivableUpdateData = receivables[receivableIndex];
                    // if the update has an id then it is an update otherwise it is a create
                    const isUpdate = !!receivableUpdateData.id;
                    receivableUpdateData.invoiceDetails.orderID = selectedOrder.id; // make sure this is always set
                    let receivableResponse;
                    if (isUpdate) {
                        receivableResponse = await client
                            .invoice()
                            .adminPortal.receivable.update(receivableUpdateData.id, receivableUpdateData);
                    } else {
                        receivableResponse = await client.invoice().adminPortal.receivable.create(receivableUpdateData);
                    }
                    // override the receivable in place
                    handleReceivableChanges(receivableResponse, receivableIndex, {
                        isAPIResponse: true,
                    });

                    return receivableResponse;
                }),
            );
            setShowToast({
                severity: 'success',
                message: 'Receivables Successfully Updated',
            });
        } catch (error) {
            console.error(error);
            setShowToast({
                severity: 'error',
                message: 'Failed to save receivables',
            });
            return;
        } finally {
            setIsLoadingOrder(false);
        }
        refetchOrder();
    };

    const handleReceivableSaveProduct = async () => {
        if (!selectedOrder || !selectedOrderID?.serviceGroupingID) {
            throw new Error('Order is not loaded');
        }
        setIsLoadingOrder(true);
        // save inv
        try {
            await Promise.all(
                receivablesChanges.map(async (receivableIndex) => {
                    const receivableUpdateData = receivables[receivableIndex];
                    // if the update has an id then it is an update otherwise it is a create
                    const isUpdate = !!receivableUpdateData.id;
                    receivableUpdateData.invoiceDetails.serviceOrderID = selectedOrder.id; // make sure this is always set
                    let receivableResponse;
                    if (isUpdate) {
                        receivableResponse = await client
                            .invoice()
                            .adminPortal.receivable.update(receivableUpdateData.id, receivableUpdateData);
                    } else {
                        // receivableResponse = await client.invoice().adminPortal.receivable.create(receivableUpdateData);
                        // create an invoice grouping
                        const invoiceGrouping = await client.universalService().invoice.create({
                            // @ts-expect-error idk why ts can't pickup on the top if check
                            serviceGroupingID: selectedOrderID.serviceGroupingID,
                        });
                        // makes sure the correct relationships are set
                        receivableUpdateData.invoiceDetails.productInvoiceGroupingID = invoiceGrouping.id;
                        receivableUpdateData.invoiceDetails.serviceGroupingID = selectedOrderID.serviceGroupingID;
                        receivableUpdateData.invoiceDetails.invoiceNumber = undefined;
                        receivableResponse = await client.invoice().adminPortal.receivable.create(receivableUpdateData);
                    }
                    // override the receivable in place
                    handleReceivableChanges(receivableResponse, receivableIndex, {
                        isAPIResponse: true,
                    });

                    return receivableResponse;
                }),
            );
            setShowToast({
                severity: 'success',
                message: 'Receivables Successfully Updated',
            });
        } catch (error) {
            console.error(error);
            setShowToast({
                severity: 'error',
                message: 'Failed to save receivables',
            });
            return;
        } finally {
            setIsLoadingOrder(false);
        }
        refetchOrder();
    };

    const handleReceivableSave = selectedOrderType === 'roll-off' ? handleReceivableSaveRollOff : handleReceivableSaveProduct;

    const handlePayableSaveRollOff = async () => {
        if (!selectedOrder) throw new Error('Order is not loaded');
        setIsLoadingOrder(true);
        // save bill
        try {
            await Promise.all(
                payablesChanges.map(async (payableIndex) => {
                    const payableUpdateData = payables[payableIndex];
                    let payableResponse;
                    // if the update has an id then it is an update otherwise it is a create
                    const isUpdate = !!payableUpdateData.id;
                    payableUpdateData.invoiceDetails.orderID = selectedOrder.id; // make sure this is always set
                    payableUpdateData.invoiceDetails.orderNumber = selectedOrder.orderNumber?.toString() || '';
                    payableUpdateData.vendorName ??= selectedOrder.vendorName || undefined;
                    // if it is an update we need to update the invoice
                    if (isUpdate) {
                        payableResponse = await client.invoice().adminPortal.payable.update(payableUpdateData.id, payableUpdateData);
                    } else {
                        const waysteOrderID = payables.find((payable) => payable.invoiceDetails.waysteOrderID)?.invoiceDetails
                            .waysteOrderID;

                        if (waysteOrderID) {
                            payableUpdateData.invoiceDetails.waysteOrderID = waysteOrderID;
                        }

                        payableResponse = await client.invoice().adminPortal.payable.create(payableUpdateData);
                    }

                    // override the payable in place
                    handlePayableChanges(payableResponse, payableIndex, {
                        isAPIResponse: true,
                    });

                    return payableResponse;
                }),
            );
            setShowToast({
                severity: 'success',
                message: 'Payables Successfully Updated',
            });
        } catch (error) {
            console.error(error);
            setShowToast({
                severity: 'error',
                message: 'Failed to save payables',
            });
            return;
        } finally {
            setIsLoadingOrder(false);
        }
        refetchOrder();
    };

    const handlePayableSaveProduct = async () => {
        if (!selectedOrder) throw new Error('Order is not loaded');
        setIsLoadingOrder(true);
        // save bill
        try {
            await Promise.all(
                payablesChanges.map(async (payableIndex) => {
                    const payableUpdateData = payables[payableIndex];
                    let payableResponse;
                    // if the update has an id then it is an update otherwise it is a create
                    const isUpdate = !!payableUpdateData.id;
                    payableUpdateData.invoiceDetails.serviceOrderID = selectedOrder.id; // make sure this is always set
                    payableUpdateData.invoiceDetails.orderServiceLocation = selectedOrder?.serviceLocation;
                    payableUpdateData.vendorName ??= selectedOrder.vendorName || undefined;
                    // if it is an update we need to update the invoice
                    if (isUpdate) {
                        payableResponse = await client.invoice().adminPortal.payable.update(payableUpdateData.id, payableUpdateData);
                    } else {
                        payableResponse = await client.invoice().adminPortal.payable.create(payableUpdateData);
                    }

                    // override the payable in place
                    handlePayableChanges(payableResponse, payableIndex, {
                        isAPIResponse: true,
                    });

                    return payableResponse;
                }),
            );
            setShowToast({
                severity: 'success',
                message: 'Payables Successfully Updated',
            });
        } catch (error) {
            console.error(error);
            setShowToast({
                severity: 'error',
                message: 'Failed to save payables',
            });
            return;
        } finally {
            setIsLoadingOrder(false);
        }
        refetchOrder();
    };

    const handlePayableSave = selectedOrderType === 'roll-off' ? handlePayableSaveRollOff : handlePayableSaveProduct;

    const handleCreateNewReceivable = () => {
        let newInvoice: Invoice.ReceivableTransport;
        // create new invoice
        if (selectedOrderType === 'roll-off') {
            newInvoice = {
                invoiceDetails: {
                    orderServiceLocation: selectedOrder?.serviceLocation,
                    orderNumber: selectedOrder?.orderNumber?.toString() || '',
                    invoiceNumber: String(receivables.length + 1),
                    taxRate: selectedOrder?.taxRate || 0,
                    lineItems: [
                        {
                            id: '',
                            description: '',
                            quantity: 1,
                            unitPrice: 0,
                            taxable: false,
                            itemName: 'CC Fee',
                        },
                    ],
                    void: false,
                    payments: [],
                    refunds: [],
                    status: 'DRAFT',
                    id: '',
                    totalDollars: 0,
                    total: 0,
                    isWaysteInvoice: false,
                    paidInFull: false,
                    remainingBalance: 0,
                    remainingBalanceDollars: 0,
                    syncedWithAccounting: false,
                    taxAmount: 0,
                    taxAmountDollars: 0,
                    type: 'receivable',
                    orderID: selectedOrder?.id || '',
                    term: getInvoiceTerm(selectedOrder?.paymentTerm || ''),
                },
                customerID: customer?.id || '',
                id: '',
                customerCompanyName: customer?.companyName || '',
                customerName: customer?.contacts
                    ? `${getPrimaryContact(customer)?.firstName} ${getPrimaryContact(customer)?.lastName}` || ''
                    : '',
            };
            // universal-service receivable
        } else if (selectedOrderType === 'universal-service') {
            if (!selectedServiceGrouping) throw new Error('Service Grouping not loaded' + selectedOrderID?.serviceGroupingID);

            newInvoice = {
                invoiceDetails: {
                    invoiceNumber: String(selectedServiceGrouping.receivables.length + 1),
                    taxRate: selectedOrder?.taxRate || 0,
                    lineItems: [],
                    void: false,
                    payments: [],
                    refunds: [],
                    status: 'DRAFT',
                    id: '',
                    totalDollars: 0,
                    total: 0,
                    isWaysteInvoice: false,
                    paidInFull: false,
                    remainingBalance: 0,
                    remainingBalanceDollars: 0,
                    syncedWithAccounting: false,
                    taxAmount: 0,
                    taxAmountDollars: 0,
                    type: 'receivable',
                    term: getInvoiceTerm(selectedOrder?.paymentTerm || ''),
                },
                customerID: customer?.id || '',
                id: '',
            };
        } else {
            throw new Error('Order type not set');
        }

        handleReceivableChanges(newInvoice);
    };

    const handleCreateNewPayable = (data: {
        haulerID: string;
        invoiceNumber: string;
        haulerName: string;
        lineItems?: Invoice.LineItemInputTransport[];
    }) => {
        // create new bill
        // create new invoice
        let newPayable: Invoice.PayableTransport;
        if (selectedOrderType === 'roll-off') {
            newPayable = {
                invoiceDetails: {
                    orderServiceLocation: selectedOrder?.serviceLocation,
                    invoiceNumber: data.invoiceNumber,
                    taxRate: 0,
                    lineItems: [],
                    void: false,
                    payments: [],
                    refunds: [],
                    status: 'DRAFT',
                    id: '',
                    totalDollars: 0,
                    total: 0,
                    isWaysteInvoice: payables.some((payable) => payable.invoiceDetails.isWaysteInvoice),
                    waysteOrderNumber:
                        payables.find((payable) => payable.invoiceDetails.waysteOrderNumber)?.invoiceDetails.waysteOrderNumber || undefined,
                    paidInFull: false,
                    remainingBalance: 0,
                    remainingBalanceDollars: 0,
                    syncedWithAccounting: false,
                    taxAmount: 0,
                    taxAmountDollars: 0,
                    type: 'receivable',
                    orderID: selectedOrder?.id || '',
                    term: 30,
                },
                haulerID: data.haulerID || payables[0]?.haulerID || '',
                vendorName: data.haulerName,
                readyForPayment: false,
                id: '',
            };
            // universal-service payable
        } else {
            newPayable = {
                invoiceDetails: {
                    invoiceNumber: data.invoiceNumber,
                    taxRate: 0,
                    lineItems: [],
                    void: false,
                    payments: [],
                    refunds: [],
                    status: 'DRAFT',
                    id: '',
                    totalDollars: 0,
                    total: 0,
                    isWaysteInvoice: payables.some((payable) => payable.invoiceDetails.isWaysteInvoice),
                    waysteOrderNumber:
                        payables.find((payable) => payable.invoiceDetails.waysteOrderNumber)?.invoiceDetails.waysteOrderNumber || undefined,
                    paidInFull: false,
                    remainingBalance: 0,
                    remainingBalanceDollars: 0,
                    syncedWithAccounting: false,
                    taxAmount: 0,
                    taxAmountDollars: 0,
                    type: 'receivable',
                    serviceOrderID: selectedOrderID?.serviceOrderID || '',
                    serviceGroupingID: selectedOrderID?.serviceGroupingID || '',
                    orderServiceLocation: selectedOrder?.serviceLocation,
                    term: 30,
                },
                haulerID: data.haulerID || payables[0]?.haulerID || '',
                vendorName: data.haulerName,
                readyForPayment: false,
                id: '',
            };
        }

        if (data.lineItems) {
            newPayable.invoiceDetails.lineItems.push(...(data.lineItems as Invoice.LineItemTransport[]));
        }

        handlePayableChanges(newPayable);
    };

    const handleVoidIssuedInvoice = async (receivableId: string) => {
        if (!receivableId || !customer) {
            setShowToast({
                severity: 'error',
                message: 'Something went wrong. Please refresh the page and try again.',
            });
            return;
        }
        setIsLoadingOrder(true);
        try {
            const receivableResponse = await client
                .invoice()
                .adminPortal.receivable.update(receivableId, { invoiceDetails: { void: true } });
            setShowToast({
                severity: 'success',
                message: 'Receivables Successfully Updated',
            });
            setIsLoadingOrder(false);
            const confirmed = await getConfirmation({
                title: 'Send Void Invoice Email?',
                message: 'Would you like to send an email to the customer notifying them of the voided invoice and attaching a copy of it?',
                cancelText: 'No',
                confirmText: 'Yes, Send Email',
            });
            if (confirmed) {
                setIsLoadingOrder(true);
                const response = await client.invoice().adminPortal.receivable.pdf.fetch(receivableId);
                const blob = new Blob([response], { type: 'application/pdf' });
                const currentInvoicePDF = ((await blobToBase64(blob)) as string).split(',')[1];
                if (!currentInvoicePDF) {
                    setShowToast({
                        severity: 'warning',
                        message: 'No email sent. Something went wrong loading the PDF. Please try again.',
                    });
                    setIsLoadingOrder(false);
                    return;
                }
                const attachments = [
                    {
                        content: currentInvoicePDF,
                        type: 'application/pdf',
                        filename: 'voided-invoice.pdf',
                        disposition: 'attachment',
                    },
                ];
                const contactEmail = getCustomerToAndCCEmails('billing', customer);
                const destination = formatEmailDestination(contactEmail.to, contactEmail.toContact.firstName || '', contactEmail.cc);
                const body = {
                    first_name: contactEmail.toContact.firstName || '',
                    invoice_number: `${receivableResponse.invoiceDetails.orderNumber || selectedServiceGrouping?.orderNumber || selectedOrder?.orderNumber} - ${receivableResponse.invoiceDetails.invoiceNumber}`,
                };
                await client.notification().adminPortal.createInstantNotification({
                    handler: 'sendgrid',
                    topic: 'invoice-voided',
                    destination,
                    body: JSON.stringify(body),
                    directAttachments: attachments,
                });
            }
            refetchOrder();
        } catch (error) {
            console.error(error);
            setShowToast({
                severity: 'error',
                message: 'Failed to void invoice',
            });
        } finally {
            setIsLoadingOrder(false);
        }
    };

    const values = useMemo(
        () => ({
            setListView,
            refetchOrder,
            handleReceivableChanges,
            handlePayableChanges,
            handleReceivableSave,
            handlePayableSave,
            handleCreateNewReceivable,
            handleCreateNewPayable,
            setView,
            handleVoidIssuedInvoice,
            view,
            showInvoiceDrawer,
            showSubscriptionInvoicesDrawer,
            isLoadingOrder,
            selectedOrder,
            customer,
            receivables,
            payables,
            hasChanges,
            listView,
            selectedOrderType,
            selectedServiceGrouping,
        }),
        [
            showInvoiceDrawer,
            showSubscriptionInvoicesDrawer,
            isLoadingOrder,
            selectedOrder,
            customer,
            receivables,
            payables,
            hasChanges,
            view,
            listView,
            selectedOrderType,
            selectedServiceGrouping,
        ],
    );

    return <BillingContext.Provider value={values}>{children}</BillingContext.Provider>;
};
