import { Customer } from '@alliance-disposal/transport-types';
import { DataGrid, DataGridProps } from '@wayste/sour-ui';
import { moneyFormatter } from '@wayste/utils';
import { PlusIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import DetailsCardWrapper from '../../../components/DetailsCardWrapper';
import { routes } from '../../../utils';
import { PaymentRowType } from './customer-details';

type CustomerPaymentsProps = {
    customer: Customer.AllianceCustomerTransport;
    payments: PaymentRowType[];
};

const CustomerPayments = ({ customer, payments }: CustomerPaymentsProps) => {
    const history = useHistory();
    const columns: DataGridProps<PaymentRowType>['columns'] = [
        {
            key: 'orderNumber',
            name: 'Order #',
        },
        {
            key: 'invoiceNumber',
            name: 'Invoice #',
            formatter: ({ row }) => `${row.orderNumber} - ${row.invoiceNumber}`,
        },
        {
            key: 'amount',
            name: 'Amount',
            formatter: ({ value }) => moneyFormatter(Number(value)),
        },
        {
            key: 'paymentReceivedDate',
            name: 'Date',
            formatter: ({ value }) => format(new Date(value as string), 'MMM d, yyyy, h:mm aaa'),
        },
        {
            key: 'paymentIdentifierString',
            name: 'Source',
        },
        {
            key: 'address',
            name: 'Address',
        },
    ];

    return (
        <DetailsCardWrapper
            heading="Payments"
            buttons={[
                {
                    label: (
                        <>
                            <PlusIcon className="mr-1 h-5 w-5" /> Add Payment
                        </>
                    ),
                    onClick: () => {
                        history.push(routes.billing.list, {
                            customer: JSON.stringify(customer),
                        });
                    },
                },
                {
                    customButton: (
                        <CSVLink
                            className="btn-secondary"
                            data={payments.map((item) => ({
                                ...item,
                                invoiceNumber: `${item.orderNumber} - ${item.invoiceNumber}`,
                                amount: moneyFormatter(item.amount),
                            }))}
                        >
                            Download Payment Data
                        </CSVLink>
                    ),
                },
            ]}
        >
            <div style={{ margin: '-16px -20px -18px' }}>
                <DataGrid rows={payments} columns={columns} />
            </div>
        </DetailsCardWrapper>
    );
};

export default CustomerPayments;
